<template>
  <div class="Career">
    <!-- <h1 class="Career-Title Wrapper Title Title--h1-smaller">{{ $t('career.title') }}</h1> -->
    <AboutCareer class="Career-Section"/>
    <CareerSteps class="Career-Section"/>
    <WorkPlay class="Career-Section"/>
    <FAQ class="Career-Section"/>
    <FormBox class="Career-Section"/>
  </div>
</template>

<script>
import AboutCareer from '../components/AboutCareer'
import CareerSteps from '../components/CareerSteps'
import WorkPlay from '../components/WorkPlay'
import FAQ from '../components/FAQ'
import FormBox from '../components/FormBox'

export default {
  name: 'Career',
  components: {
    AboutCareer,
    CareerSteps,
    WorkPlay,
    FAQ,
    FormBox
  }
}
</script>

<style lang="scss">
.Career {
  &-Title {
    margin-bottom: 60px;
    text-align: center;

    @media (min-width: $screen-l) {
      margin-bottom: 100px;
      text-align: left;
    }

    @media (min-width: $screen-xl) {
      margin-bottom: 200px;
    }
  }

  &-Section {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: ($screen-l)) {
      margin-bottom: 120px;
    }
  }
}
</style>
