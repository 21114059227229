<template>
  <div class="FAQ Wrapper">
    <h2 class="FAQ-Title Title Title--h2 Title--h2-small">FAQ</h2>

    <dl class="FAQ-List">
      <div
        class="FAQ-Item"
        :class="{ 'FAQ-Item--active': activeQuestion.includes(i) }"
        v-for="(item, i) in faq"
        :key="i"
      >
        <dt
          class="FAQ-Question"
          @click="toggleQuestion(i)"
        >
          {{ item.question }}
          <button class="FAQ-Toggle" type="button"></button>
        </dt>
        <dd
          class="FAQ-Answer"
          v-html="item.answer"
        />
      </div>
    </dl>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeQuestion: [],
      faq: [
        {
          question: 'Что такое Syndicate Group?',
          answer: '<p><span class="MarkedText">Syndicate Group</span> — крупнейший медиабаинговый холдинг на рынке. Мы инвестируем в арбитражные команды и объединяем сильнейших медиабаеров.</p><p>В Syndicate Group команды получают безлимитную оборотку и полный саппорт рабочей рутины, чтобы вы могли концентрироваться только на cвязках и проливе трафика.</p>'
        },
        {
          question: 'Как попасть к вам в команду?',
          answer: '<p>Выберите вакансию и пришлите нам ваше резюме или заполните анкету в боте <a href="https://t.me/SyndicateG_bot" target="_blank">Бот Синдикат</a>, и мы с вами обязательно свяжемся.</p>'
        },
        {
          question: 'Я могу отправить резюме на несколько позиций?',
          answer: '<p>Да, но мы советуем откликаться только на ту вакансию, которая вам действительно интересна и подходит по опыту и навыкам.</p>'
        },
        {
          question: 'У меня команда, вы сможете проинвестировать в нее?',
          answer: '<p>Мы заинтересованы в инвестировании в новые команды. Но для начала нам надо с вами познакомиться, понять, подходим мы друг другу или нет, и запустить небольшой тестовый проект. Для большей информации и старта сотрудничества напишите хэду Syndicate Group.</p>'
        },
        {
          question: 'С какими нишами и ГЕО вы работаете?',
          answer: '<p>Только гемблинг и бурж ГЕО.</p>'
        },
        {
          question: 'С какими источниками вы работаете?',
          answer: '<p>Google, Snapchat, Twitter, Facebook, TikTok, Apple Search Ads.</p>'
        }
      ]
    }
  },
  methods: {
    toggleQuestion(idx) {
      if (!this.activeQuestion.includes(idx)) {
        this.activeQuestion.push(idx)
      } else {
        this.activeQuestion = this.activeQuestion.filter(i => i !== idx)
      }
    }
  }
}
</script>

<style lang="scss">
  .FAQ {
    &-Title {
      margin-bottom: 25px;

      @media (min-width: $screen-l) {
        margin-bottom: 60px;
      }
    }

    &-Item {
      margin-bottom: 24px;

      @media (min-width: $screen-l) {
        margin-bottom: 36px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-Question {
      position: relative;
      padding-right: 45px;
      color: var(--color-text-main2);
      font-size: 20px;
      font-weight: 700;
      line-height: 1.12;
      text-transform: uppercase;
      cursor: pointer;

      @media (min-width: $screen-l) {
        font-size: 29px;
      }
    }

    &-Toggle {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 0;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3px;
        height: 30px;
        transition: all 0.25s ease-out;
        background-color: #fff;
      }

      &::before {
        transform: translate(-50%, -50%);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(90deg);
      }

      .FAQ-Item--active & {
        &::before,
        &::after {
          background-color: var(--color-text-main2);
          transition-timing-function: ease-in;
        }

        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }
    }

    &-Answer {
      overflow: hidden;
      max-height: 0;
      padding: 0;
      margin: 0;
      transition: all 0.35s ease-out;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.6;

      @media (min-width: $screen-l) {
        font-size: 16px;
      }

      &::before {
        content: '';
        display: block;
        width: 1px;
        height: 24px;
      }

      .FAQ-Item--active & {
        transition: all 0.55s ease-in;
        max-height: 500px;
      }

      a {
        color: var(--color-text-main2);
        text-decoration: underline;
      }
    }
  }

  .MarkedText {
    color: var(--color-text-main2) !important;
    text-transform: uppercase !important;
  }
</style>
